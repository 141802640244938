input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 35px;
  padding: 0;
  width: 35px;
}
