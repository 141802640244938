.reviewer-dropdown-container {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
        width: 100%;
        min-height: 45px;
        padding: 0px 8px 0px 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        border-radius: 4px;
        border: 1px solid var(--american-silver) !important;
        box-shadow: none !important;

    }

    .css-13cymwt-control:hover {
        border-color: var(--american-silver) !important;
    }

    // .css-t3ipsp-control {
    //     box-shadow: none !important;
    //     min-height: 45px;
    //     padding: 0px 8px 0px 8px;

    // }

    .css-t3ipsp-control:hover {
        border-color: var(--american-silver) !important;
    }

    .select__menu {
        min-width: 660px;
    }



    .custom-option {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        border-bottom: 1px solid #D1D1D1;
        background: #FFFFFF;
        width: 100%;



    }

    .user-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .w-fit-content {
        width: fit-content;
    }

    @media (max-width: 768px) {
        .custom-option {
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;
            flex-wrap: wrap;
        }

        .user-info {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .user-info>div {
            width: 100%;
            margin-bottom: 10px;
        }

        .text-14-400-18 {
            font-size: 14px;
        }

        .text-12-400-15 {
            font-size: 12px;
        }

        .s-48 {
            width: 36px;
            height: 36px;
        }



    }

    @media (max-width: 576px) {
        .custom-option {
            padding: 8px;
        }

        .user-info {
            align-items: flex-start;
        }

        .user-info>div {
            margin-bottom: 8px;
            width: 100%;
        }

        .text-14-400-18 {
            font-size: 12px;
        }

        .text-12-400-15 {
            font-size: 10px;
        }

        .s-48 {
            width: 30px;
            height: 30px;
        }

        .select__menu {
            max-width: 200px;

        }


    }

    @media (min-width: 576px) and (max-width: 900px) {
        .select__menu {
            max-width: 300px;
        }
    }
}

.allocate-button {
    position: absolute;
    top: 5px;
    right: -120px;
}