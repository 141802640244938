#new-proffetional-dashboard {
  .counter-block {
    padding: 15px 24px;
    .counter-image-block {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background: #eff3ff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .perks-block {
    width: 100px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background: #f4f4fc;
    border-radius: 10px;
    padding-top: 16px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
    color: #2148c0;
    row-gap: 5px;
  }
  .perks-block:hover {
    background: #2148c0;
    cursor: pointer;
    span {
      color: #fff !important;
    }
    img {
      filter: invert(26%) sepia(100%) saturate(0%) brightness(250%)
        contrast(1000%);
    }
  }

  .journal-block {
    display: flex;
    align-items: center;
    gap: 10px;
    .image-block {
      width: 51px;
      height: 51px;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-right: 10px;
      padding: 5px;
      border-radius: 50%;
      img {
        border-radius: 50%;
      }
    }
  }

  .post-file {
    background-color: #e9eeff;
    color: #2148c0;
    min-height: 28px;
    min-width: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
  }
  .post-image-video {
    height: 190px;
    // max-height: 190px;
    // height: 100%;
    border-radius: 9px;
    .btn-vd-play {
      background-color: rgba(211, 0, 0, 0.8);
      color: var(--white);
      padding: 3px 20px 0px 20px;
      font-size: 20px;
      border-radius: 10px;
      border: 1px solid var(--white);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .bottom-block {
    border-top: 0.5px solid #d1d1d1;
    padding: 16px 29px 20px 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }

  .bottom-block > div:last-child {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }

  .custom-pagination-block {
    

    .custom-pagination {
      display: flex;
      align-items: center;
      gap: 40px;
    }

    .active {
      cursor: pointer !important; // Ensures pointer styling for clickable elements
    }

    .active-img {
      filter: brightness(0) saturate(100%) invert(24%) sepia(94%)
        saturate(1572%) hue-rotate(211deg) brightness(89%) contrast(98%); // Makes the image brighter
    }

    .view-all-post{
      position: absolute;
    right: 30px;
    bottom: 20px;
    cursor: pointer;
    }

    
  }

  .swiper-pagination-bullet {
    height: 12px;
    width: 12px;
    background-color: #d1d1d1 !important;
    opacity: unset;
  }

  .swiper-pagination-bullet-active {
    height: 12px;
    width: 12px;
    background-color: #2148c0 !important;
  }

  // .swiper-wrapper{
  //   padding-bottom: 100px;
  // }

  .people-know-block {
    display: flex;
    align-items: start;
    border-radius: 10px;
    padding: 20px 16px;
    gap: 15px;
    background: #f4f4fc;
    border: 1px solid transparent;


    .new-people {
      padding: 5px 10px;
      border-radius: 20px;
      background: #fff2de;
      color: #e75110;
      font-size: 8px;
      font-weight: 600;
      line-height: 9.38px;
    }

    .image-block {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3.4px solid #ffffff;
      img {
        border-radius: 50%;
      }
      .name-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff2de;
        color: #e75110;
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        border-radius: 50%;
      }
    }
  }

  .hover-card:hover{
    border: 1px solid #2148C0;
    background: #ECF4FF;
    border-radius: 10px;
  }

  .group-list-block {
    border: 0.5px solid #d1d1d1;
    border-radius: 10px;
    .group-item {
      padding: 15px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #000000;
    }

    .group-item:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .group-item:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
