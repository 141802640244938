.submitted-paper-status-id {
  .time-line-container{
    padding-inline: 40px;
  }
  .steps-block {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active {
    background-color: #2148c0 !important;
    color: white !important;
  }

  .not-active {
    background-color: #d1d1d1 !important;
    color: #746866 !important;
  }
  .step-label {
    width: 130px;
    min-width: max-content;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    color: #746866;
    text-align: center;
    margin: auto;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .active-label {
    color: #2148c0 !important;
  }
  .in-active-label {
    color: #746866 !important;
  }
  .sparator-block {
    width: 230px;
    display: flex;
    justify-content: center;
    align-items: center;

    .hr-line {
      width: 100%;
    }
    .active-line {
      border-top: 5px solid #2148c0 !important;
    }
    .in-active-line {
      border-top: 5px solid #d1d1d1 !important;
    }
  }

  //   @media (max-width: 768px) {
  //     flex-direction: column;

  //     .step {
  //       margin-bottom: 20px;

  //       .step-line {
  //         display: none;
  //       }
  //     }
  //   }
}
