.multiple-user-select {
    position: relative;

    .multiple-input-container {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        padding: 7.5px 14px 7.5px 14px;
        border-radius: 4px;
        border: 1px solid var(--american-silver);
        cursor: pointer;

        .center-input {
            flex-grow: 1;

            .searc-center-input {
                input {
                    border: unset;
                    font-size: 14px;
                    font-weight: 400;
                    outline: unset;
                    width: 130px;
                    text-overflow: ellipsis;
                }
            }

        }

        .invite-block {
            background-color: var(--new-car);
            color: var(--white);
            padding: 5px 8px 5px 8px;
            border-radius: 2px;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            cursor: pointer;
        }
    }

    .search-list-block {
        position: absolute;
        left: 0;
        width: 100%;
        background-color: var(--white);
        z-index: 999;
        max-height: 300px;
        overflow: auto;
    }
}

// .new-user-dropdown-container {

//     .search-input-block {
//         position: relative;
//         display: flex;
//         flex-direction: column;
//         border-radius: 4px;
//         border: 1px solid var(--american-silver);
//         width: 100%;
//         min-height: 45px;
//         padding: 0px 6px 0px 14px;

//         .selected-users-container {
//             // position: absolute;
//             display: flex;
//             flex-wrap: wrap;
//             gap: 10px;
//             top: 12px;

//             .selected-user {
//                 display: flex;
//                 align-items: center;
//                 padding: 4px 8px;
//                 background-color: #E9EEFF;
//                 border-radius: 2px;
//                 font-size: 15px;
//                 font-weight: 500;
//                 line-height: 15px;
//                 color: #2148C0;
//             }

//         }

//         .is-selected-icon-wrapper {
//             bottom: 16px;
//         }

//         .icon-wrapper {
//             top: 14px;
//         }

//         .common-icon-wrapper {
//             position: absolute;
//             display: flex;
//             align-items: center;
//             margin-right: 8px;
//             left: 12px;
//             color: #746866;
//         }

//         input {
// width: 100%;
// min-height: 45px;
// padding: 0px 6px 0px 14px;
// font-size: 16px;
// font-weight: 400;
// line-height: 19.5px;
// // border-radius: 4px;
// // border: 1px solid var(--american-silver);
// border: none !important;
// outline: none !important;
// cursor: pointer;

//             &:hover {
//                 outline: unset;
//             }

//             &:active {
//                 outline: unset;
//             }

//             &:focus {
//                 outline: unset;
//             }
//         }

//         .elipsis-input {
//             padding-right: 100px;
//             white-space: nowrap;
//             text-overflow: ellipsis;
//         }

//         .active-text {
//             ::placeholder {
//                 color: var(--black);
//                 opacity: 1;
//             }

//             :-ms-input-placeholder {
//                 color: var(--black);
//                 opacity: 1;
//             }

//             ::-ms-input-placeholder {
//                 color: var(--black);
//                 opacity: 1;
//             }
//         }

//         .placeholder-color {
//             ::placeholder {
//                 color: var(--granite-gray);
//                 opacity: 1;
//                 /* Firefox */
//             }

//             :-ms-input-placeholder {
//                 color: var(--granite-gray);
//             }

//             ::-ms-input-placeholder {
//                 color: var(--granite-gray);
//             }
//         }

//         .is-selected-icon-block {
//             bottom: 12px;
//         }

//         .icon-block {
//             top: 12px;
//         }

//         .common-icon-block {
//             position: absolute;
//             right: 18px;
//             color: var(--gray);
//         }

//         .is-selected-invite-block {
//             bottom: 12px;
//         }

//         .invite-block {
//             top: 10px;
//         }

//         .common-invited-block {
//             position: absolute;
//             right: 40px;
//             background-color: var(--new-car);
//             color: var(--white);
//             padding: 5px 8px 5px 8px;
//             border-radius: 2px;
//             font-size: 12px;
//             font-weight: 500;
//             line-height: 15px;
//             cursor: pointer;
//         }

// .search-list-block {
//     position: absolute;
//     left: 0;
//     width: 100%;
//     background-color: var(--white);
//     z-index: 999;
// }

//         .selected-users-list {
//             display: flex;
//             flex-wrap: wrap;
//             gap: 10px;
//             margin-bottom: 10px;
//         }

//         .selected-user {
//             display: inline-flex;
//             align-items: center;
//             background-color: #f0f0f0;
//             border-radius: 20px;
//             padding: 5px 10px;
//             font-size: 14px;
//             font-weight: 500;
//             color: #333;
//             position: relative;

//             .user-name {
//                 margin-right: 8px;
//             }

//             .remove-user-btn {
//                 background: none;
//                 border: none;
//                 cursor: pointer;
//                 font-size: 16px;
//                 color: #ff0000;
//                 margin-left: 5px;

//                 &:hover {
//                     color: #ff5a5a;
//                 }
//             }
//         }
//     }
// }

// // .new-user-dropdown-container {
// //     position: relative;

// //     .css-1u9des2-indicatorSeparator {
// //         display: none;
// //     }

// //     .css-13cymwt-control {
// //         width: 100%;
// //         min-height: 45px;
// //         padding: 0px 8px 0px 8px;
// //         font-size: 16px;
// //         font-weight: 400;
// //         line-height: 19.5px;
// //         border-radius: 4px;
// //         border: 1px solid var(--american-silver) !important;
// //     }

// //     .css-1fdsijx-ValueContainer {
// //         padding: 0px 8px 0px 4px;
// //     }

// //     .css-t3ipsp-control {
// //         box-shadow: none !important;
// //         border-color: unset !important;
// //         outline: none !important;
// //         min-height: 45px;
// //         border-radius: 4px;
// //         border: 1px solid var(--american-silver) !important;
// //         padding: 0px 8px 0px 8px;

// //     }

// //     .css-1p3m7a8-multiValue {
// //         background-color: #E9EEFF;
// //         color: #2148C0;
// //         border-radius: 2px;
// //     }

// //     .css-wsp0cs-MultiValueGeneric {
// //         color: #2148C0;
// //         font-size: 12px;
// //         font-weight: 500;
// //         line-height: 15px;
// //         padding: 5px;

// //     }

// //     .css-12a83d4-MultiValueRemove:hover {
// //         background-color: unset;
// //         color: unset;
// //     }

// //     .icon-wrapper {
// //         display: flex;
// //         align-items: center;
// //         justify-content: center;
// //         width: 24px;
// //     }

// //     .invite-block {
// //         position: absolute;
// //         top: 34px;
// //         right: 40px;
// //         background-color: var(--new-car);
// //         color: var(--white);
// //         padding: 5px 8px 5px 8px;
// //         border-radius: 2px;
// //         font-size: 12px;
// //         font-weight: 500;
// //         line-height: 15px;
// //         cursor: pointer;
// //     }

// //     .css-1hb7zxy-IndicatorsContainer {
// //         padding: 0;
// //     }
// // }