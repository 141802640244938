.academic-info-container {
    .red-round-block {
        padding: 5px 15px;
        border-radius: 30px;
        border: 1px solid #FF4D00;
    }

    .card-border-bottom {
        border: 1px solid #D9E6F7
    }
}