.trending-research-pedio-container {
    user-select: none;
    // overflow: hidden;

    .image-section {
        // width: 100%;
        height: 250px;
    }

    .child-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom center;
    }

    .next-button {
        width: 25px;
        position: absolute;
        top: 50%;
        right: 0;
        background: #FFFFFF;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        box-shadow: 0px 4px 10px 0px #2148C01A;
        z-index: 999;
    }

}