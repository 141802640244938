.new-certificate-generate-popup {
  .modal-content {
    width: 1320px !important;
  }

  .modal-lg {
    --bs-modal-width: 1320px !important;
  }
}
.new-certificate-container {
  width: 100%;
  display: flex;
  gap: 30px;
}
.left-section{
  width: 50%;
}
.right-section{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.preview-container {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  height: 100%;
}
.preview-output {
  width: 100% !important;
  height: auto !important;
  overflow-y: scroll;
  margin-bottom: 20px;
  max-height: 515px;
}
.dynamic-preview-section {
  height: 100%;
}

.previw-download-button-border {
  height: 100%;
  border-right: 1px solid #746866;
}

@media screen and (max-width:1330px){
  .new-certificate-container {
    flex-wrap: wrap !important;
  }
  .left-section{
    width: 100% !important;
  }
  .right-section{
    width: 100% !important;
    
  }
}

@media screen and (max-width: 992px) {
  .new-certificate-container {
    width: unset;
  }
}

@media screen and (max-width: 600px) {
  .new-certificate-container {
    width: unset;
  }
}

@media (min-width: 0px) and (max-width: 999px) {
  .new-certificate-generate-popup {
    .modal-content {
      width: 100% !important;
    }

    // .modal-lg {
    //   --bs-modal-width: 1000px !important;
    // }
    #modal-container {
      min-width: 100% !important;
    }
  }
}
