#dropdown-button-container {
  .dropdown-body-container {
    max-height: 300px;
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px transparent;
      background-color: var(--ghost-white);
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: var(--ghost-white);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: var(--gray);
    }
  }
}
