.segment-user-detail-container{
    .segment-user-details-border {
        border: 0.75px solid #d1d1d1;
        border-radius: 5px;
      }
    //   .segment-user-details-border:hover {
    //     border: 0.75px solid #2148c0;
    //     border-radius: 5px;
    //     cursor: pointer;
    //   }
      .right-25{
        right: 25px;
      }
      .top-25{
        top: 25px;
      }
      .width-25{
        width: 25%;
        text-align: end;
      }
      .width-70{
        width: 70%;
        // text-align: end;
      }
      .all-user-check{
        background-color: #F4F4FC;
        border-radius: 5px;
        padding: 10px 12px;
      }
}