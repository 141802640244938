#whatsapp-chat-container {
  position: fixed;
  bottom: 8px;
  right: 10px;
  font-size: 22px;
  border-radius: 50%;
  cursor: pointer;
  height: 43px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
