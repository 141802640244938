.mentorship-mentors {

    .all-menteed {
        .hover-text:hover {
            color: #C11515;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    #mentor-session-container {
        .title-hover {

            font-size: 16px;
            font-weight: 500;
            color: #164371;
        }

        .title-hover:hover {
            color: #2148C0;
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: #2148C0;
        }

        .key-points {
            border-radius: 4px;
            padding: 6px 12px;
            background-color: #F2F6FCE5;
        }


    }
}