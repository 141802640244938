#InputOTP-container {
  ul {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: unset;
    padding: unset;

    li {
      float: none;
      list-style: none;
      display: inline-block;
      padding: 0px 4px 0px 4px;

      input {
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        background: #ffffff;
        border: 1px solid #9a9a9a;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        height: 52px;
        width: 52px;
        border-radius: 50%;

        &.square-box {
          border-radius: 10%;
          /* Make the box square when isSquare is true */
        }

        &:focus {
          outline: none;
          box-shadow: 0px 4px 16px #00000024;
        }
      }
    }
  }
}