#mentorship-user-container {
    #mentee-all-mentor-container {
        .selected-border {
            border: 1px solid #007BFF;
        }

        .share {
            border: 1px solid #2148C0;
            border-radius: 4px;
            padding: 5px;
        }
    }
}