.similar-skills-container {
    .carousel-control-prev {
        display: none;
    }

    .carousel-control-next {
        top: 50%;
        right: -10px;
        position: absolute;
        width: 25px;
        height: 25px;
        box-shadow: 0px 3px 10px 0px #00000029;
        background-color: #FFFFFF;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11111;
        opacity: 1
    }

    .fade-slide {
        opacity: 0;
        transition: all 0.5s ease-in;
    }

    .fade-slide.active {
        opacity: 1;
    }

    .skills-block-orange {
        background-color: #FFFFFF;
        border: 1px solid #FFC05C;
    }

    .skills-block-ocean {
        background-color: #FFFFFF;
        border: 1px solid #53EFD4;
    }

    .skills-block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 20px;


        // .arrow-block {
        //     top: 50%;
        //     right: -10px;
        //     position: absolute;
        //     width: 25px;
        //     height: 25px;
        //     box-shadow: 0px 3px 10px 0px #00000029;
        //     background-color: #FFFFFF;
        //     border-radius: 100%;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     z-index: 11111;
        // }


        .upper-block {
            position: relative;
            width: 100%;
            height: 90px;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .start-block {
                right: 10px;
                top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 100%;
            }

            .profile-image-block {
                position: absolute;
                right: 50%;
                left: 50%;
                bottom: -50%;
            }
        }


        .skill-border-top {
            border-top: 1px solid #DADADA;
        }
    }

}