#course-dropdown-container {
  width: 100%;
  .course-input-block {
    position: relative;
    cursor: pointer;
    .close-con {
      position: absolute;
      right: 14px;
      top: 8px;
      font-size: 20px;
      cursor: pointer;
      color: lightgrey;
    }
    .icon-con {
      position: absolute;
      right: 14px;
      top: 10px;
      cursor: pointer;
      color: lightgrey;
    }
    .course-list {
      position: absolute;
      top: 47px;
      width: 100%;
      z-index: 999;
      background-color: hsl(0, 0%, 100%);
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
      max-height: 300px;
      overflow: auto;
      .course-item {
        font-size: 16px;
        font-weight: 400;
        padding: 8px 15px;
        border-bottom: 1px solid lightgray;
        cursor: pointer;
      }
      .course-item:hover {
        background-color: var(--new-car-light);
      }
    }
    input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 40px 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .remove-placeholder-color {
    ::placeholder {
      color: var(--raisin-black);
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      color: var(--raisin-black);
    }
    ::-ms-input-placeholder {
      color: var(--raisin-black);
    }
  }
}
