#user-payment-account-details-container {
    .copy-button {
        color: var(--new-car);
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .left-padding {
        padding-left: 18px;
    }

}