#speaker-details-container {
  .committee-members-card-container {
    text-align: center;
    .shadow-block {
      padding: 14px;
      margin-top: 14px;
      .image-block {
        img {
          max-height: 194px;
          max-width: 242px;
        }
      }
    }
  }
}
