#FollowUpDropdown-container {
  width: 100%;

  .select__control {
    width: 100%;
    height: 45px;
    padding: 0px 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    // border-radius: 4px;
    border: 1px solid var(--american-silver);
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    outline: none;

    :focus {
      border: unset;
      outline: none;
      box-shadow: unset;
    }

    :active {
      box-shadow: unset;
    }

    option {
      margin: 15px 0px;
      padding: 15px 0px;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      color: var(--black-olive);
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    border-color: unset;
    outline: none !important;
    height: 45px;
    padding: 0px 6px;
    border-radius: 4px;
    border: 1px solid var(--american-silver);
  }

  .css-4ljt47-MenuList {
    padding: unset;
  }

  .select__option {
    border-bottom: 1px solid #ebeff2;
  }

  .select__option:last-child {
    border-bottom: unset;
  }

  .select__menu {
    z-index: 9;
  }

  .select__menu-list,
  .css-4ljt47-MenuList,
  .css-1116k7n-MenuList {
    z-index: 999;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px transparent;
      background-color: var(--ghost-white);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: var(--ghost-white);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: var(--gray);
    }
  }

  .select__placeholder {
    color: var(--granite-gray);
    white-space: nowrap;
  }

  .css-qc6sy-singleValue {
    font-size: 16px;
    font-weight: 400;
    color: black;
  }

  .css-1u9des2-indicatorSeparator,
  .select__indicator-separator {
    display: none;
  }

  .css-1insrsq-control {
    height: 45px;
  }

  .css-lr9fot-singleValue {
    color: unset;
  }

  .css-26l3qy-menu {
    z-index: 9;
  }
}