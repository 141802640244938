.conferences-and-events-container {
  .event-list-card-block {
    border: 1px solid #ebeff2;
    .date-time-block {
      background-color: var(--new-car);
      color: var(--white);
      font-size: 23px;
      font-weight: 500;
      line-height: 20px;
      height: 141px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .right-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 20px;
      gap: 10px;
      .location-button-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
      }
      #button-container {
        button {
          height: 42px !important;
          img {
            height: 17px;
          }
        }
        .h-46 {
          height: 46px !important;
        }
      }
    }
  }
}
@media screen and  (max-width : 450px) {
  .right-block .location-button-block .location-name{
    max-width : 180px;
  }
}
