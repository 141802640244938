.upload-input-container {
    width: 100%;
}

.upload-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1.2px dashed #d1d1d1;
    border-radius: 8px;
    padding: 47px;
    height: 147px;
}

.file-uploaded-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
}

.file-uploaded-box .file-name,
.file-uploaded-box .file-size {
    text-align: center;
}

.file-chunk {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    background-color: #e8e8e8;
    border-radius: 5px;
}

.delete-icon {
    cursor: pointer;
    margin-left: auto;
}