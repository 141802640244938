.ai-suggestion-container {
  .aiButtonHELP {
    border-radius: 5.78px;
    background: linear-gradient(93.94deg, #792cf2 1.94%, #4221fb 96.85%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 4px 10px;
    position: absolute;
    top: 0px;
    right: 19px;
    cursor: pointer;
    text-align: center;
  }
  .ai-suggestion-box {
    position: absolute;
    top: 36px;
    right: 20px;
    width: 380px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 15px;
    font-size: 14px;
    z-index: 999;
  }

  .ai-title {
    font-weight: bold;
    color: #6666ff;
  }

  .ai-description {
    font-size: 13px;
    margin-top: 10px;
    color: #333;
  }

  .ai-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .ai-credits {
    font-size: 12px;
    color: var(--3d3d);
  }

  .ai-buttons {
    display: flex;
  }

  .ai-retry,
  .ai-use {
    padding: 5px 12px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }

  .ai-retry {
    background: #f0f0f0;
    border: 1px solid #ccc;
    margin-right: 10px;
  }

  .ai-use {
    background: #6666ff;
    color: white;
    border: none;
  }
}
