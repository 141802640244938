.pass-container {
    width: 100%;
    max-width: 750px;

    .user-pass {
        width: 100%;
        height: auto;
        border-radius: 20px;
    }

    @media (max-width: 760px) {
        .user-pass {
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
}