.calendar-filter-layout-container {
    .week-title {
        width: 14.28571428571429%;
        color: var(--2e44);
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            border: 1px solid;
            border-color: transparent;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }

    .month-day {
        width: 14.28571428571429%;
        font-size: 14px;
        font-weight: 500;
        color: var(--2e44);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2px;

        .day-block {
            position: relative;
            border: 1px solid;
            border-color: transparent;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18%;
        }
    }

    .selected-date {
        background-color: #2148c0;
        color: white;
        border-radius: 10%;
    }

    .in-range {
        background-color: #e7edff;
        color: #2148c0;
    }

    .hover-bg:not(.selected-date, .in-range):hover {
        background-color: #f3f3f3;
        color: black;
        border-radius: 10%;
    }

    .hover-bg-new:not(.selected-date) {
        background-color: #e7edff;
        color: black;
    }




    .left-head-indicator {
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;

        div {

            &:first-child {
                position: absolute;
                left: 0;
                top: 0;
            }

            &:last-child {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .left-dropdown-month,
        .left-dropdown-year {
            position: absolute !important;
            right: 31%;
            top: 64%;
            box-shadow: 0px 4px 12px #bfcfff2b;
            display: flex;
            flex-direction: column;
            gap: 10px;
            z-index: 999;
            background-color: white !important;
            height: 265px;
            width: 132px;
            overflow-y: scroll;
            text-align: center;
            padding: 10px 0;
        }


    }

    .right-head-indicator {
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;

        div {

            &:first-child {
                position: absolute;
                right: 0;
                top: 0;
            }

            &:last-child {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .right-dropdown-month,
        .right-dropdown-year {
            position: absolute !important;
            right: 31%;
            top: 64%;
            box-shadow: 0px 4px 12px #bfcfff2b;
            display: flex;
            flex-direction: column;
            gap: 10px;
            z-index: 999;
            background-color: white !important;
            height: 265px;
            width: 132px;
            overflow-y: scroll;
            text-align: center;
            padding: 10px 0;
        }
    }
}