#mentee-details-container {
    .completed {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #8095A8;
    }

    .upcoming_pending {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #00A74D;
    }

    .upcoming_accepted {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #00A74D;
    }
}