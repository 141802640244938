#profile-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .round-border {
    border: 2px solid #0083d6;
  }

  .minWidth {
    min-width: 115px !important;
  }

  .verify-icon {
    position: absolute;
    right: 10px;
    bottom: 18px;
    width: 23px;
    background: #ffffff;
    height: 23px;
    border-radius: 50%;
    display: flex;
  }

  @mixin image-size($size, $f-size, $f-weight) {
    height: $size;
    width: $size;
    max-width: $size;
    font-size: $f-size !important;
    font-weight: $f-weight !important;
    line-height: 20px !important;
  }

  .s-18 {
    @include image-size(18px, 8px, 600);
  }

  .s-22 {
    @include image-size(22px, 10px, 600);
  }

  .s-30 {
    @include image-size(30px, 15px, 600);
  }

  .s-34 {
    @include image-size(34px, 16px, 600);
  }

  .s-40 {
    @include image-size(40px, 18px, 600);
  }

  .s-48 {
    @include image-size(48px, 20px, 600);
  }

  .s-52 {
    @include image-size(52px, 24px, 600);
  }

  .s-60 {
    @include image-size(60px, 30px, 600);
  }

  .s-68 {
    @include image-size(68px, 32px, 600);
  }

  .s-100 {
    @include image-size(100px, 50px, 600);
  }

  .s-168 {
    @include image-size(168px, 50px, 600);
  }
  .s-134 {
    @include image-size(134px, 50px, 600);
  }

  .s-115 {
    @include image-size(115px, 50px, 600);
  }

  .s-114 {
    @include image-size(114px, 50px, 600);
  }

  .s-112 {
    @include image-size(112px, 50px, 600);
  }

  .s-125 {
    @include image-size(125px, 60px, 600);
  }
  .s-136 {
    @include image-size(136px, 60px, 600);
  }

  .s-163 {
    @include image-size(163px, 60px, 600);
  }

  .s-200 {
    @include image-size(200px, 60px, 600);
  }
}
