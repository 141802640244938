#my-task-detail-container {
    .input-border {
        border: 1px solid #D1D1D1;
        // padding: 7px 21px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        // min-width: 50px;
        width: 50px;
        min-height: 32px;
        text-align: center;

        .score-input {
            height: 32px;
            width: 100%;
            text-align: center;

            :disabled {
                background-color: #e6e6e6;
                color: #212121;
            }

        }

        :focus-visible {
            outline: none !important;
        }
    }

    .score-main-block {
        gap: 10px;
    }

    .score-label {
        min-width: 196px;
        width: fit-content;
        gap: 10px;

    }

    .score-value-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .add-remove-button {
        border: unset !important;
        background-color: unset !important;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
    }
}

@media only screen and (min-width:992px) and (max-width:1200px) {
    .score-main-block {
        gap: unset !important;
    }

    .score-label {
        min-width: 196px;
        width: fit-content;
        gap: unset !important;

    }

    .score-value-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: unset !important;
    }

}