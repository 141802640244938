#OldCertificate-container {
  .input-block-box {
    position: relative;
    .btn-b-block {
      position: absolute;
      right: 5px;
      top: 5px;
    }
    input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 80px 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: auto;
    }
    ::placeholder {
      color: var(--granite-gray);
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      color: var(--granite-gray);
    }
    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }
  }
}
.outside-certificate-form {
  .btn-out-save {
    height: 48px;
    border-radius: 23px;
    border: unset;
    padding: 0px 50px;
    background-color: var(--new-car);
    color: var(--white);
  }
  .out-input {
    width: 100%;
    border-radius: 4px;
    outline: none;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--american-silver);
    height: 45px;
    padding: 13px 16px 13px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: auto;
    ::placeholder {
      color: var(--granite-gray);
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      color: var(--granite-gray);
    }
    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }
  }
}
