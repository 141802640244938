.paper-status-filter-container {
    position: relative;

    .popup-block {
        position: absolute;
        top: 35px;
        right: -15px;
        background-color: #fff;
        width: 200px;
        z-index: 99;
        border-radius: 5px;
        border: 1px solid #d6d6d6;
        text-align: left;

        .list-block {
            padding: 20px;
            height: 330px;
            padding-bottom: 5px;
            overflow: auto;

        }
    }
}