#text-area-container {
  textarea {
    width: 100%;
    border-radius: 4px;
    outline: none;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--american-silver);
    padding: 13px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
  }

  :disabled {
    background-color: #e6e6e6;
    color: #212121;
  }
}