#overlap-profile-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .avatar {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    width: 30px;
    height: 30px;
  }


  .avatar:not(:first-child) {
    margin-left: -18px;
    -webkit-mask: radial-gradient(circle 16px at 5px 50%,
        transparent 99%,
        #fff 100%);
    mask: radial-gradient(circle 16px at 5px 50%, transparent 99%, #fff 100%);
  }

  .avatar img {
    width: 100%;
    display: block;
  }
}


#overlap-new-profile-container {
  display: flex;
  align-items: center;

  .new-avatar {
    position: relative;
    // margin-left: -20px;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #fff;
    /*   -webkit-transition: .2s ease; */
    /*   transition: .2s ease; */
  }

  .new-avatar:first-child {
    margin-left: 0;
    /* No negative margin for the first avatar */
  }

  .new-avatar:nth-child(n+2) {
    margin-left: -18px;
    /* No negative margin for the first avatar */
  }

  .new-avatar img {
    width: 100%;
    display: block;
    border-radius: 50%;
  }

}