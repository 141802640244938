.skill-select-popup {
    position: absolute;
    right: 18%;
    background-color: var(--white);
    box-shadow: 0px 4px 12px #0000001f;
    border-radius: 4px;
    z-index: 9;
    max-width: calc(100vw - 16px);
    margin-top: 10px;

    .searchBar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .skill-submit-btn {
        background-color: #d8e1ff;
        color: #2148c0;
        border: none;
        font-weight: bold;

        &:hover {
            background-color: #d8e1ff;
            color: #2148c0;
            border: none;
            font-weight: bold;
        }
    }

    .skill-select-top-left {
        color: #2148c0;

        i {
            rotate: 30deg
        }
    }

    #searchQueryInput {
        width: 100%;
        height: 2.8rem;
        background: #e8f3ff;
        outline: none;
        border: none;
        border-radius: 1.625rem;
        padding: 0 3.5rem 0 2.5rem;
        font-size: 1rem;
    }

    #searchQuerySubmit {
        width: 3.5rem;
        height: 2.8rem;
        margin-left: -3.5rem;
        background: none;
        border: none;
        outline: none;
    }

    #skillButton {
        .skill-btn {
            color: #28527c;
            background-color: var(--white);
            border: 1px solid #244f7a;
            border-radius: 25px;
            font-weight: bold;
            white-space: nowrap;
            padding: 10px 15px;
        }

        .active {
            color: #fff;
            background-color: #2148c0;
            border-radius: 25px;
            border: 1px solid #244f7a;
            font-weight: bold;
            white-space: nowrap;
            padding: 10px 15px;
        }
    }

    #searchQuerySubmit:hover {
        cursor: pointer;
    }

    .searchBar {
        position: relative;

        .search-icon {
            display: flex;
            position: absolute;
            top: 11px;
            left: 15px;

            img {
                height: 21px;
                width: 21px;
            }
        }
    }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 8px;
    margin-right: 10px;
}

::-webkit-scrollbar-track {
    background-color: #c9d6e3;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #a0b0be;
}

@media (max-width: 576px) {
    .skill-select-popup {
        width: 100vw;
        left: 10px;
    }
}