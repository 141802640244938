#password-input-container {
  width: 100%;
  .password-container {
    position: relative;
    .password-icon {
      position: absolute;
      right: 13px;
      top: 12px;
    }
    .password-suggetion-popup {
      position: absolute;
      top: 45px;
      width: 100%;
      background-color: var(--white);
      padding: 5px 10px;
      padding-bottom: 5px;
      z-index: 999;
      user-select: none;
    }
    .password-input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    ::placeholder {
      color: var(--granite-gray);
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder {
      color: var(--granite-gray);
    }
    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }
  }
  //   .password-indicator {
  //     --bs-progress-height: 4px;
  //     margin-top: 3px;
  //   }
  //   .success-1 {
  //     .progress-bar {
  //       background-color: var(--bs-red);
  //     }
  //   }
  //   .success-2 {
  //     .progress-bar {
  //       background-color: var(--bs-orange);
  //     }
  //   }
  //   .success-3 {
  //     .progress-bar {
  //       background-color: var(--bs-yellow);
  //     }
  //   }
  //   .success-4 {
  //     .progress-bar {
  //       background-color: var(--bs-teal);
  //     }
  //   }
  //   .success-5 {
  //     .progress-bar {
  //       background-color: var(--bs-green);
  //     }
  //   }
}
