.rbac-user-type-container {
  .rbac-users-type {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 30px 34px;
    background-color: var(--f3ff);
    color: var(--black-olive);
    border: 2px solid #fff;

    &:hover {
      border: 2px solid #2148c0;
      color: var(--new-car) !important;
      cursor: pointer;
      img {
        filter: brightness(0) saturate(100%) invert(21%) sepia(52%)
          saturate(3876%) hue-rotate(218deg) brightness(93%) contrast(86%);
      }
    }
  }
}
