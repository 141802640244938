.rbac-container{
    .role-base-users{
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 20px 14px;
        background-color: var(--f3ff);
        color: var(--black-olive);
        border: 2px solid #fff;

        &:hover{
            border: 2px solid #2148C0;
            color: var(--new-car) !important;
            cursor: pointer;
        }
    }
}