.segment-users-container{
    .segment-user-border {
        border: 0.75px solid #d1d1d1;
        border-radius: 5px;
      }
      .segment-user-border:hover {
        border: 0.75px solid #2148c0;
        border-radius: 5px;
        cursor: pointer;
      }
      .right-25{
        right: 25px;
      }
      .width-25{
        width: 25%;
        text-align: end;
      }
      .width-75{
        width: 75%;
        // text-align: end;
      }
    
}