.profile-info-container {
  .aiButton {
    padding: 12px 13px 12px 13px;
    border-radius: 5.78px;
    background: linear-gradient(93.94deg, #792cf2 1.94%, #4221fb 96.85%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .profile-image {
    position: relative;
    // width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    .share-icon {
      position: absolute;
      background-color: white;
      border-radius: 100%;
      right: 0;
      top: 0;
      box-shadow: 0px 1px 4px 0px #0000001f;
    }

    .profile-fit-image {
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
      overflow: hidden;
      width: 168px;
      height: 100%;
      object-fit: contain;
      border: 2px solid #0083d6;
      border-radius: 80px;
    }
  }

  .premium-crown-outline {
    background-color: white;
    border: 1px solid #1668d3;
  }
}
