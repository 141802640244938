#mentee-all-mentor-container {
    .all-mentor-card-container {
        height: calc(100vh - 180px);
        overflow: auto;

    }

    .single-mentor-details-container {
        height: calc(100vh - 180px);
        overflow: auto;
    }
}