#register-container {
  margin-top: 50px;
  .card-padding {
    padding: 44px 54px 30px 54px;
  }
  #custom-card-container {
    min-width: 560px;
    width: auto;
  }

  #member-details-view-container {
    width: 700px;
  }
}
