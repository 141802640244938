.register-popup {
  .modal-content {
    width: 1500px !important;
  }

  .modal-lg {
    --bs-modal-width: 1500px !important;
  }
}

#popup-register-container {
  @font-face {
    font-family: 'Gilroy';
    src: url('../../../../public/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  .text-gilroy {
    font-family: 'Gilroy', sans-serif !important;
  }

  .left-side {
    // height: 100vh;
    width: 340px;

    .step-container {
      .step-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #fff;
        border: 2px solid #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #555;
        position: relative;
        z-index: 1;
      }

      .step-icon.active {
        background-color: var(--3dcd);
        color: #fff;
        border-color: var(--3dcd);
      }
      .step-icon::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 55px;
        background: #ddd;
        left: 50%;
        top: -60px;
        transform: translateX(-50%);
        z-index: 0;
        border-left: 2px dashed #bbb; /* Dashed line */
      }

      .step-1 .step-icon::before {
        display: none;
      }

      .text-muted {
        font-size: 18px !important;
        font-weight: 400 !important;
        font-family: 'Gilroy', sans-serif !important;
        color: black;
      }
    }

    .card-container {
      .custom-btn {
        background-color: #ffffff;
        color: var(--3dcd);
        border-radius: 39px;
        height: 43px;
        width: auto;
        padding: 0px 15px;
        font-family: 'Gilroy', sans-serif !important;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .main-logo {
    margin-bottom: 52px;
  }

  .details-list {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-wrap: wrap;
    // gap: 16px;

    .details-block {
      display: flex;
      align-items: center;
      gap: 10px;

      .number-block {
        border: 1.5px solid #606060;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #606060;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 10px;
      }

      .active-selection {
        height: 27px;
        width: 27px;
        // margin-right: 10px;
      }
    }

    .border-saprator {
      width: 50px;
      height: 0px;
      border: 1px dashed rgba(112, 112, 112, 0.8);
      // margin: 0px 20px;
    }

    .active-border {
      border-color: var(--new-car);
    }
  }
}

@media screen and (max-width: 992px) {
  #popup-register-container {
    width: unset;

    // background-color: pink;
    .details-list {
      .border-saprator {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #popup-register-container {
    width: unset;

    // .details-list {
    //   align-items: baseline;
    //   flex-direction: column;

    //   .border-saprator {
    //     display: none;
    //   }
    // }
  }
}

@media (min-width: 0px) and (max-width: 999px) {
  .register-popup {
    .modal-content {
      width: 100% !important;
    }

    // .modal-lg {
    //   --bs-modal-width: 1000px !important;
    // }
    #modal-container {
      min-width: 100% !important;
    }
  }
}
