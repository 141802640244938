#calendar-view-container {
  .icon-button {
    background: #2148c01f;
    color: var(--new-car);
    height: 30px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 50%;
    font-weight: 600;
    cursor: pointer;
  }

  .rbc-month-view {
    border: unset;
  }

  .rbc-header {
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #232a3e;
    padding: 8px;
  }

  .rbc-off-range-bg,
  .rbc-off-range {
    background-color: unset;
    color: unset;
    opacity: 0.5;
  }

  .rbc-date-cell {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #232a3e;
    padding: 8px;
  }

  .rbc-day-bg+.rbc-day-bg {
    border-left: 1px solid #e9e9e9;
  }

  .rbc-month-row+.rbc-month-row {
    border-top: 1px solid #e9e9e9;
  }

  .rbc-header {
    border-bottom: 1px solid #e9e9e9;
  }

  .rbc-row-bg {
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
  }

  .rbc-month-row:last-child {
    border-bottom: 1px solid #e9e9e9;
  }

  .rbc-header+.rbc-header {
    border-left: unset;
  }

}

.rbc-overlay {
  position: absolute;
  z-index: 9999;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}