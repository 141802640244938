#time-picker-container {
  width: 100%;

  .input-container {
    position: relative;

    .react-datepicker-wrapper {
      display: block;
    }

    .calender-icon {
      position: absolute;
      right: 20px;
      top: 12px;

      img {
        height: 18px;
      }
    }

    input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
    }

    :disabled {
      background-color: #e6e6e6;
      color: #212121;
    }

    ::placeholder {
      color: var(--granite-gray);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      color: var(--granite-gray);
    }

    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px transparent;
        background-color: var(--ghost-white);
        border-radius: 10px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: var(--ghost-white);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: var(--gray);
      }
    }

    .react-datepicker-popper[data-placement^="bottom"] {
      padding-top: 5px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .react-datepicker__time-container {
      width: 120px;

      .react-datepicker__time .react-datepicker__time-box {
        width: 120px;
      }
    }
  }
}