#mentorship-management-component {
  .pending-count {
    position: absolute;
    top: 0;
    right: -15px;
    width: 20px;
    height: 20px;
    background-color: #2148c0;
    border-radius: 100%;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
