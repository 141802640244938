#proffetional-dropdown-container {
  position: relative;
  .search-input-block {
    position: relative;
    input {
      width: 100%;
      height: 45px;
      padding: 0px 6px 0px 14px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      border-radius: 4px;
      border: 1px solid var(--american-silver);
      cursor: pointer;
      &:hover {
        outline: unset;
      }
      &:active {
        outline: unset;
      }
      &:focus {
        outline: unset;
      }
    }
    .elipsis-input {
      padding-right: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .active-text {
      ::placeholder {
        color: var(--black);
        opacity: 1;
      }
      :-ms-input-placeholder {
        color: var(--black);
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: var(--black);
        opacity: 1;
      }
    }

    .placeholder-color {
      ::placeholder {
        color: var(--granite-gray);
        opacity: 1; /* Firefox */
      }
      :-ms-input-placeholder {
        color: var(--granite-gray);
      }
      ::-ms-input-placeholder {
        color: var(--granite-gray);
      }
    }

    .icon-block {
      position: absolute;
      top: 12px;
      right: 18px;
      color: var(--gray);
    }
    .invite-block {
      position: absolute;
      top: 10px;
      right: 40px;
      background-color: var(--new-car);
      color: var(--white);
      padding: 2px 6px 2px 6px;
      border-radius: 2px;
      font-size: 14px;
    }
  }
  .search-list-block {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    background-color: var(--white);
    z-index: 999;
  }
}
