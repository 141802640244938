.segment-management-container {
  .conference-segment-border {
    border: 0.75px solid #d1d1d1;
    border-radius: 5px;
  }
  .conference-segment-border:hover {
    border: 0.75px solid #2148c0;
    border-radius: 5px;
    cursor: pointer;
  }

  .text-dots-conference-segment {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .conference-segment-name {
    color: #3d3d3d;
  }
  .conference-segment-name:hover {
    color: #2148c0;
  }
}
