.citation-dot {
    border-radius: 50%;
    height: 5px;
    width: 5px;
    background-color: #212121;
    margin-left: 20px;
}

.citation-format {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 10px;
}