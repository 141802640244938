.wave-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100px;
    overflow: hidden;
    padding: 20px;
}

.wave {
    width: 8px;
    height: 50px;
    background: linear-gradient(130deg, #2148c0, #2148c01c);
    margin: 0 5px;
    animation: wave 1.5s ease-in-out infinite;
    border-radius: 30px;
}

//for simple wave
.wave:nth-child(2) {
    animation-delay: 0.1s;
}

.wave:nth-child(3) {
    animation-delay: 0.2s;
}

.wave:nth-child(4) {
    animation-delay: 0.3s;
}

.wave:nth-child(5) {
    animation-delay: 0.4s;
}

.wave:nth-child(6) {
    animation-delay: 0.5s;
}

.wave:nth-child(7) {
    animation-delay: 0.6s;
}

.wave:nth-child(8) {
    animation-delay: 0.7s;
}

.wave:nth-child(9) {
    animation-delay: 0.8s;
}

.wave:nth-child(10) {
    animation-delay: 0.9s;
}

//for color full wave

// .wave:nth-child(2) {
//     animation-delay: 0.1s;
//     background: linear-gradient(135deg, #66bb6a, #43a047);
// }

// .wave:nth-child(3) {
//     animation-delay: 0.2s;
//     background: linear-gradient(135deg, #ffca28, #fbc02d);
// }

// .wave:nth-child(4) {
//     animation-delay: 0.3s;
//     background: linear-gradient(135deg, #ff7043, #e64a19);
// }

// .wave:nth-child(5) {
//     animation-delay: 0.4s;
//     background: linear-gradient(135deg, #ab47bc, #8e24aa);
// }

// .wave:nth-child(6) {
//     animation-delay: 0.5s;
//     background: linear-gradient(135deg, #5c6bc0, #3949ab);
// }

// .wave:nth-child(7) {
//     animation-delay: 0.6s;
//     background: linear-gradient(135deg, #26a69a, #00897b);
// }

// .wave:nth-child(8) {
//     animation-delay: 0.7s;
//     background: linear-gradient(135deg, #d4e157, #c0ca33);
// }

// .wave:nth-child(9) {
//     animation-delay: 0.8s;
//     background: linear-gradient(135deg, #ffa726, #fb8c00);
// }

// .wave:nth-child(10) {
//     animation-delay: 0.9s;
//     background: linear-gradient(135deg, #ef5350, #e53935);
// }

@keyframes wave {
    0% {
        transform: scaleY(0.1);
        opacity: 0.1;
    }

    50% {
        transform: scaleY(1.2);
        opacity: 1;
    }

    100% {
        transform: scaleY(0.1);
        opacity: 0.1;
    }
}