.add-user-popup {
  .modal-content {
    width: 1320px !important;
  }

  .modal-lg {
    --bs-modal-width: 1320px !important;
  }
}

.create-segment-container {
  border: 0.75px solid #d1d1d1;
  border-radius: 5px;
}

@media screen and (max-width: 992px) {
  .add-user-container {
    width: unset;
  }
}

@media screen and (max-width: 600px) {
  .add-user-container {
    width: unset;
  }
}

@media (min-width: 0px) and (max-width: 999px) {
  .add-user-popup {
    .modal-content {
      width: 100% !important;
    }

    // .modal-lg {
    //   --bs-modal-width: 1000px !important;
    // }
    #modal-container {
      min-width: 100% !important;
    }
  }
}
