.lead-status-warm {
    border: 1.1px solid #E8A700;
    color: #E8A700;
    background: #FFF4CC;
    border-radius: 5.48px;
    padding: 4px 12px 4px 12px;
}

.followup-main-block {
    width: 50%;
}

@media only screen and (max-width:1200px) {
    .followup-main-block {
        width: 100% !important;
    }

}