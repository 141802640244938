#switch-container {
    display: flex;

    .switch {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 20px;
    }
  
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    //   border: 1px solid #61c454;
      background-color: #D1D1D1;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 3px;
      right: 0;
      bottom: 3px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  
    input:checked + .slider {
        background-color: #1C6DD0;
    }
    input:checked + .slider:before {
      background-color: #fff;

    }
  
    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
    .slider.round {
      border-radius: 100px;
    }
  
    .slider.round:before {
      border-radius: 50%;
    }
  }