#button-container {
  .btn {
    height: 48px;
  }

  .btn-round {
    border-radius: 23px;
  }

  .btn-square {
    border-radius: 2px;
  }

  .btn-round-premium {
    border-radius: 4px;
  }

  .primary-dark {
    background-color: var(--new-car);
    color: var(--white);
  }

  .primary-light {
    background-color: #2148c01c;
    color: var(--new-car);
  }

  .primary-light-outline {
    background-color: #eff3ff;
    color: var(--new-car);
    border: 1px solid var(--new-car);
  }

  .unset-primary {
    background-color: unset;
    color: var(--new-car);
  }

  .primary-white {
    background-color: white;
    color: white;
    border: unset;
    border-color: unset;
  }

  .primary-gray {
    background-color: #ededed;
    color: #555555;
    border: unset;
    border-color: unset;
  
  }

  .gray-dark {
    background-color: #e7e8eb;
    color: #3d3d3d;
  }

  .danger-dark {
    background-color: var(--bs-danger);
    color: var(--white);
    // border: unset;
    // border-color: unset;
  }

  .danger-delete {
    border: 1px solid #e10000;
    background-color: white;
  }

  .danger-light {
    background-color: var(--danger-light);
    color: var(--white);
    // border: unset;
    // border-color: unset;
  }

  .light-outline {
    background-color: var(--white);
    color: var(--black-olive);
    border: 1px solid var(--gray);
  }

  .black-outline-gray-bg {
    background-color: #ebeffacc;
    color: black;
    border: 1px solid black;
  }

  .primary-outline {
    background-color: var(--white);
    color: var(--new-car);
    border: 1px solid var(--new-car);
  }

  .primary-outline1 {
    background-color: var(--white);
    color: var(--new-car);
    border: 1.5px solid var(--new-car);
  }
  .primary-outline-car {
    background-color: var(--eff3);
    color: var(--new-car);
    border: 1.5px solid var(--new-car);
  }

  .danger-outline {
    background-color: var(--white);
    border: 1px solid var(--bs-danger);
    color: var(--bs-danger);
  }

  .delete-outline {
    background-color: var(--white);
    color: var(--bs-danger);
    border: 1px solid var(--bs-danger);
    height: unset;
  }

  .sky-blue-outline {
    background-color: var(--white);
    color: var(--skyblue);
    border: 1px solid var(--skyblue);
  }

  .sky-blue-outline1 {
    background-color: var(--white);
    color: var(--text-blue);
    border: 1px solid var(--text-blue);
  }

  .purple-bg {
    background-color: var(--purple);
    color: var(--white);
  }

  .light-blue-outline {
    background-color: white;
    color: #164371;
    border: 1px solid #6f8194;
  }

  .light-gray-delete {
    background-color: #F4F4FC;
    color: #3D3D3D;
    border: 1px solid #F4F4FC;
  }

  .edit-blue-outline {
    background-color: white;
    color: #164371;
    border: 1px solid #164371;
  }

  .dark-primary-outline {
    background-color: white;
    color: #113dcd;
    border: 1px solid #113dcd;
    height: unset;
  }

  .premium-crown-outline {
    background-color: white;
    border: 1px solid #1668d3;
  }

  .light-yellow {
    background-color: rgba(144, 144, 144, 0.25);
  }

  .h-40 {
    height: 40px;
  }

  .h-34 {
    height: 34px;
  }

  .follow-dark-green {
    background-color: var(--a31a);
    color: #ffffff;
    border: 1px solid #00a31a;
  }

  .follow-dark-orange {
    background-color: var(--4c00);
    color: #ffffff;
    border: 1px solid #ff4c00;
  }

  .connect-fill-orange {
    background-color: var(--4c00);
    color: #ffffff;
    height: unset;
  }

  .orange-outline {
    background-color: var(--white);
    border: 1px solid #ff4c00;
    color: #ff4c00;
  }

  .mentor-outline {
    background-color: white;
    border: 1px solid #8a2796;
    color: #8a2796;
    height: unset;
  }

  .speaker-outline {
    background-color: white;
    border: 1px solid #00a31a;
    color: #00a31a;
    height: unset;
  }

  .secure-button {
    background-color: #028f43;
    color: #ffffff;
    height: unset;
  }

  .h-45 {
    height: 45px;
  }

  .h-39 {
    height: 39px;
  }

  .h-38 {
    height: 38px;
  }

  .h-35 {
    height: 35px;
  }

  .h-30 {
    height: 30px;
  }

  .h-20 {
    height: 20px;
  }

  .hover-button:hover {
    background-color: var(--white);
    color: var(--new-car);
  }

  .AI {
    padding: 14px;
    border-radius: 5px;
    border: 0.95px;
    background: var(--new-car);
    color: var(--white);
  }
}
