#remark-confirmation-container {
    .radio-dropdown-menu-custom {
        bottom: 0px;
        left: 120px;

        .radio-form-container {
            display: flex !important;
            flex-direction: column !important;
            gap: 20px !important;
        }
    }

    .dropdown-menu {
        --bs-dropdown-min-width: 137px !important;
        --bs-dropdown-max-width: 137px !important;
        --bs-dropdown-border-color: #fff !important;
        border-radius: 11px !important;
        padding: 17px !important;
        box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004D !important;
        background-color: #ffffff !important;
    }
}