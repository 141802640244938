.post-type-container {
  position: relative;
  background: #2148c01a;
  color: var(--new-car);
  border-radius: 17px;
  padding: 6px 13px 6px 16px;
  cursor: pointer;
  .post-type-data-container {
    position: absolute;
    right: 0;
    width: 220px;
    z-index: 9;
    background-color: var(--white);
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.18);
    top: 40px;
    border-radius: 4px;
    border: 1px solid var(--bs-border-color);
    // padding: 0px 5px;
    .post-option {
      font-size: 15px;
      font-weight: 400;
      padding: 15px 20px;
      border-bottom: 1px solid var(--bs-border-color);
      color: var(--black-olive);
      &:last-child {
        border-bottom: unset;
      }
    }
    .active-post-option {
      background-color: var(--new-car);
      color: var(--white);
    }
    .active-post-option:first-child {
      border-radius: 4px 4px 0px 0px;
    }
    .active-post-option:last-child {
      border-radius: 0px 0px 4px 4px;
    }
  }
}
.post-input-container {
  position: relative;
  #post-input {
    width: 100%;
    height: 45px;
    padding: 13px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    border-radius: 4px;
    border: 1px solid var(--american-silver);
    &:focus {
      outline: none;
    }
  }
  .create-post-icon-container {
    position: absolute;
    top: 2.5px;
    right: 10px;
    width: max-content;
  }
}
