#post-details-container {
  .img-block {
    // height: 325px;
    // display: flex;
    // justify-content: left;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: cover;
      overflow: hidden;
    }
  }
  .send-btn {
    display: flex;
    align-items: center;
    background-color: var(--new-car);
    width: fit-content;
    color: var(--white);
    gap: 10px;
    padding: 8px 15px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
  }
  video {
    --video-width: 426;
    --video-height: 240;
    aspect-ratio: var(--video-width) / var(--video-height);
    width: 100%;
    height: auto;
    object-fit: fill;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    overflow: hidden;
  }
}
