.career-objectives-info-container {
    .red-round-block {
        padding: 5px 15px;
        border-radius: 30px;
        border: 1px solid #FF4D00;
    }

    .speaker-outline {
        border: 1px solid #00A31A;
    }

    .mentor-outline {
        border: 1px solid #8A2796;
    }

    // .react-tooltip-arrow {
    //     left: unset !important;
    // }


}