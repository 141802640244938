#custom-card-container {
  box-shadow: 0px 4px 12px #bfcfff2b;
  border-radius: 8px;
  background-color: var(--white);
  max-width: calc(100vw - 0px);
  word-wrap: break-word;
}

.url-section {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: row !important;
}

.edit-url-btn {
  width: 72px !important ;
  display: flex;
  flex-wrap: wrap;
}

.edit-url-icon {
  width: 16px !important;
  height: 16px !important;
  display: block;
}

.left-line {
  height: 1px;
}

.right-line{
  height: 1px;
}

.or-top-margin {
  margin-top: -9px;
}

.text-design {
  width: 48%;
}

.available-section {
  top: 10px;
  right: 14px;
}

.available-text {
  top: 3px;
  right: 25px;
}

@media (max-width: 990px) {
  .text-design {
    width: 100%;
  }
}