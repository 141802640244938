.dropdown-menu-custom {
    top: auto;
    right: 0;
}

.message-indicator {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    top: 4px;
    right: 6px;
    position: absolute;
}

.dropdown-menu {
    --bs-dropdown-min-width: 24rem !important;
    --bs-dropdown-max-width: 24rem !important;
    border-radius: 10px !important;
    box-shadow: 0px 4px 12px #bfcfff2b !important;
}