.new-paper-details-container {
    .br {
        border-radius: 2px;
    }



    .lead-status-warm {
        border: 1.1px solid #E8A700;
        color: #E8A700;
        background: #FFF4CC;
        border-radius: 5.48px;
        padding: 4px 12px 4px 12px;
    }

    .lead-status-hot {
        border: 1.1px solid #E34646;
        color: #E34646;
        background: #FDDCDC;
        border-radius: 5.48px;
        padding: 4px 12px 4px 12px;
    }

    .lead-status-cold {
        border: 1.1px solid #597CEA;
        color: #597CEA;
        background: #DCE3FF;
        border-radius: 5.48px;
        padding: 4px 12px 4px 12px;
    }

    .lead-status-default {
        border: 1.1px solid #000000;
        color: #000000;
        background: #505050;
        border-radius: 5.48px;
        padding: 4px 12px 4px 12px;
    }


    .custom-border-bottom {
        border-bottom: 1px solid #D1D1D1;
    }

    .custom-border-end {
        border-right: 1px solid #D1D1D1;
    }

    .new-back {
        background-color: blanchedalmond;
    }

    .custom-padding {
        padding: 20px;
    }

    .mobile-screen-header {
        display: none;
    }

    .active-text-underline {
        text-decoration: underline;
        text-decoration-color: var(--new-car);

    }

    .paper-tracking-section {
        .steps-block {
            font-size: 13px;
            font-weight: 500;
            color: white;
            height: 33px;
            width: 33px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;
        }

        .steps-block-follow {
            height: 20px;
            width: 20px;
            min-width: 20px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 14px;
            border: 5px solid #d1d1d1;
        }



        .active {
            background: #2148C0;
        }

        .not-active {
            background: #746866;
        }

        .sparator-block {
            width: 33px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .hr-line {
                height: 100%;
                border-right: 1px dashed #D1D1D1;
            }
        }

        .sparator-block-follow {
            width: 19px;
            margin-right: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            .hr-line-follow {
                height: 100%;
                border-right: 5px solid #D1D1D1;
            }
        }
    }

    .reviewer-state-block {
        .allocate-time-chunks {
            background: #F4F4FC;
            border-radius: 5px;
            padding: 7px 19px;
            color: #3D3D3D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;

            &.active {
                background: #ECF4FF;
                border: 1px solid #2148C0;
                border-radius: 5px;

            }
        }

        .paper-status-chunks {
            background: #F4F4FC;
            border-radius: 19px;
            padding: 7px 15px;
            color: #3D3D3D;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;

        }

        .w-fit-content {
            width: fit-content;
        }

        .reviewer-dropdown-menu-custom {
            top: 40px;
            right: 0;

        }


        .dropdown-menu {
            width: 660px;
            --bs-dropdown-min-width: 24rem !important;
            --bs-dropdown-max-width: 24rem !important;
            border-radius: 5px !important;
            box-shadow: 0px 6px 11.8px 0px #00000040 !important;

            .options-list-block {
                width: 100%;
                height: 300px;
                overflow-y: scroll;


                .custom-option {
                    display: flex;
                    align-items: center;
                    padding: 15px 20px;
                    border-bottom: 1px solid #D1D1D1;
                    background: #FFFFFF;
                    width: 100%;

                }
            }


        }
    }



    @media (max-width:991px) {
        .new-back {
            background-color: aqua !important;
        }

        .normal-screen-header {
            display: none;
        }

        .custom-border-end {
            border-right: none !important
        }

        .custom-padding {
            padding: 0 0 0 10px !important;
        }

        .mobile-screen-header {
            display: block !important;
            border-bottom: 1px solid #D1D1D1;
        }
    }


}