#action-filter-container {
    .dropdown-toggle::before {
        display: none;
    }

    .dropdown-body-container {
        min-width: unset !important;
        width: 248px;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 18px -2px #1018287A, 0px 12px 16px -4px #1018283D;
        border: 1px solid #F4F4FC
    }

    .options-block,
    .button-block {
        padding: 13px 25px;


    }
}