#member-details-container {
  width: 900px;
  .details-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    .details-block {
      display: flex;
      align-items: center;
      gap: 10px;
      .number-block {
        border: 1.5px solid #606060;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 500;
        color: #606060;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-right: 10px;
      }
      .active-selection {
        height: 27px;
        width: 27px;
        // margin-right: 10px;
      }
    }
    .border-saprator {
      width: 50px;
      height: 0px;
      border: 1px dashed rgba(112, 112, 112, 0.8);
      // margin: 0px 20px;
    }
    .active-border {
      border-color: var(--new-car);
    }
  }
}

@media screen and (max-width: 992px) {
  #member-details-container {
    width: unset;
    // background-color: pink;
    .details-list {
      .border-saprator {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #member-details-container {
    width: unset;
    .details-list {
      align-items: baseline;
      flex-direction: column;
      .border-saprator {
        display: none;
      }
    }
  }
}
