.new-view-paper-container {
    .br-5 {
        border-radius: 5px;
    }

    .br-15 {
        border-radius: 15px;
    }

    .br-25 {
        border-radius: 25px;
    }

    .steps-block {
        font-size: 13px;
        font-weight: 500;
        color: white;
        height: 33px;
        width: 33px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
    }

    .active {
        background: #2148C0;
    }

    .not-active {
        background: #746866;
    }

    .sparator-block {
        width: 33px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .hr-line {
            height: 100%;
            border-right: 1px dashed #D1D1D1;
        }
    }

    .follow-up-details-block {
        .follow-step-block {
            background: #ECF4FF;
            height: 19px;
            width: 19px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            .inner-block {
                background: #2148C0;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

        .follow-up-sparator-block {
            width: 19px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .hr-line {
                width: 5px;
                height: 100%;
                background: #ECF4FF;

            }
        }

    }

    .pass-block {
        .pass-details {
            .user-info {
                background-image: url("../../../assets/icons/logo/passBgLogo.png");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}