#card-information-container {
    width: 100%;
    .card-input-block {
      border: 1px solid #d3d3d3;
      border-radius: 4px;
      .card-number-block {
        position: relative;
        border-bottom: 1px solid #d3d3d3;
        .card-type-block {
          position: absolute;
          top: 7px;
          right: 12px;
        }
      }
      .card-date-block {
        border-right: 1px solid #d3d3d3;
        width: 50%;
      }
      .cvv-block {
        width: 50%;
        position: relative;
        .cvv-type-block {
          position: absolute;
          top: 13px;
          right: 12px;
        }
      }
      input {
        width: 100%;
        height: 54px;
        border: unset;
        font-size: 14px;
        font-weight: 400;
        padding: 16px 23px;
      }
    }
  }
  