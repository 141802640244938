#offline-connection-container {
  width: calc(100vw - 12px);
  .body-container {
    text-align: center;
    margin-bottom: 40px;
    .image-container {
      svg {
        height: 150px;
        width: 150px;
      }
    }
    .title-container {
      font-size: 50px;
      font-weight: 700;
    }
    .message-container {
      font-size: 25px;
      font-weight: 400;
    }
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {
    .body-container {
      .image-container {
        svg {
          height: 300px;
          width: 300px;
        }
      }
      .title-container {
        font-size: 80px;
      }
      .message-container {
        font-size: 40px;
      }
    }
  }

  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) {
    .body-container {
      .image-container {
        svg {
          height: 200px;
          width: 200px;
        }
      }
      .title-container {
        font-size: 70px;
      }
      .message-container {
        font-size: 30px;
      }
    }
  }

  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .body-container {
      .image-container {
        svg {
          height: 200px;
          width: 200px;
        }
      }
      .title-container {
        font-size: 70px;
      }
      .message-container {
        font-size: 30px;
      }
    }
  }

  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {
    .body-container {
      .image-container {
        svg {
          height: 160px;
          width: 160px;
        }
      }
      .title-container {
        font-size: 50px;
      }
      .message-container {
        font-size: 20px;
      }
    }
  }

  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {
    .body-container {
      .image-container {
        svg {
          height: 150px;
          width: 150px;
        }
      }
      .title-container {
        font-size: 50px;
      }
      .message-container {
        font-size: 18px;
      }
    }
  }
}
