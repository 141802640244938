#event-details-container {
  #organizing-committee-members-container {
    height: calc(100vh - 320px);
    overflow-y: auto;
    overflow-x: hidden;
    .important-dates-block {
      background: #2148c00f;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 14px;
    }
    .committee-members-card-container {
      text-align: center;
      .shadow-block {
        box-shadow: 0px 4px 16px #00000024;
        padding: 14px;
        margin-top: 14px;
      }
    }
  }
  .aganda-session-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    .left-block {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--new-car);
      color: var(--white);
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      padding: 8px 12px;
      margin-right: 28px;
    }
    .right-block {
      border: 1px solid #cfcfcf;
      border-radius: 4px;
      padding: 9px 9px 15px 28px;
      width: calc(100% - 120px);
      position: relative;
      .star-block {
        position: absolute;
        left: -15px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #808080;
        background-color: var(--white);
        border-radius: 1px;
        padding: 2px 5px;
        z-index: 1;
      }
    }
  }


}
  // Extra small devices (phones)
  @media only screen and (max-width: 600px) {
    #event-details-container {
      #organizing-committee-members-container {
        max-width: calc(100vw - 6%);
      }
    }

    .aganda-session-container {
      flex-wrap: wrap;
      .left-block {
        margin-bottom: 10px;
      }
      .right-block {
        width: 100%;
      }
    }
  }
  