#bank-transfer-component {
    #custom-card-container{
    .date-picker{
        #date-picker-container {
            .input-container {
                .calender-icon {
                    right: 21px !important;
                }
            }
        }
    } 
}    
}