#event-post-list-container-page {
    .post-block {
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        margin-bottom: 24px;

        .post-file {
            background-color: var(--new-car);
            color: var(--white);
            min-height: 42px;
            min-width: 42px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
        }

        .top-block {
            padding: 18px 24px 0px 24px;

            .img-block {
                height: 350px;

                // display: flex;
                // justify-content: left;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    background-repeat: no-repeat;
                    background-position: bottom center;
                    background-size: cover;
                    overflow: hidden;
                }
            }
        }

        .bottom-block {
            border-top: 1px solid #ededed;
            padding: 18px 24px 18px 24px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 30px;
        }

        .bottom-block>div:last-child {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
        }

        .post-t-block {
            text-decoration: underline;
            // color: var(--new-car) !important;
            font-weight: 500 !important;
            // &:hover {
            // font-weight: 500 !important;
            //   text-decoration: underline;
            //   color: var(--new-car) !important;
            // }
        }
    }

    .btn-vd-play {
        background-color: rgba(211, 0, 0, 0.8);
        color: var(--white);
        padding: 3px 30px 0px 30px;
        font-size: 30px;
        border-radius: 10px;
        border: 1px solid var(--white);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
}

@media (max-width: 1200px) {
    #post-list-container-page {
        .post-block {
            .bottom-block {
                gap: 20px;
            }

            .bottom-block>div:last-child {
                justify-content: start;
            }
        }
    }
}