.cvBlock {
  padding: 14px;
  background-color: var(--white);
  height: calc(100vh - 136px);
  overflow-y: auto;
  .headBlock{
    background: var(--f4fc);
  }
  .mainCvBlock{
    border: 1px solid var(--f4fc)
  }
  li{
    font-size: 12px;
    font-weight: 400;
    color: var(--dark-gray);
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .empList{
    margin-bottom: 22px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .acheiList{
    margin-bottom: 22px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .jorList{
    margin-bottom: 22px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .pubList{
    margin-bottom: 22px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.cv-tabs {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  gap: 2px;
  overflow: auto;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
    background-color: var(--ghost-white);
    border-radius: 3px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: var(--ghost-white);
    scrollbar-width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: var(--gray);
  }
}
.cv-tab {
  flex: 1;
  text-align: center;
  padding: 12px 10px;
  cursor: pointer;
  color: #888;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid var(--d1d1);
  background-color: var(--ghost-white);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.cv-tab .tab-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
}
.cv-tab:hover {
  color: #4A7CFF;
}
.active {
  color: #4A7CFF;
  border-bottom: 1px solid #4A7CFF;
  background-color: var(--eff3);
}

