:root {
  --black: #000000;
  --quick-silver: #a5a5a5;
  --dark-silver: #707070;
  --granite-gray: #666666;
  --raisin-black: #212121;
  --eerie-black: #191919;
  --platinum: #e5e5e5;
  --ghost-white: #fafbff;
  --new-car: #2148c0;
  --new-car-light: #e9eeff;
  --white: #ffffff;
  --american-silver: #cecece;
  --gainsboro: #dcdcdc;
  --gray: #bcbcbc;
  --black-olive: #3d3d3d;
  --alice-blue: #e8f2ff;
  --cetacean-blue: #0d062d;
  --dark-charcole: #333333;
  --davys-gray: #555555;
  --light-primary: #ebeffacc;
  --title-navy: #112669;
  --subtitle-navy: #283765;
  --navy-light: #dde5ff;
  --danger: #dc3545;
  --moderate-blue: #475685;
  --lime-green: #49c381;
  --skyblue: #0cadfe;
  --purple: #7a55fd;
  --text-navy: #283765;
  --text-light: #f2f7ff78;
  --text-blue: #2148c0;
  --gray-blue: #2148c00d;
  --text-black: #4d4d4d;
  --light-sky: #f1f7ff;
  --light-gray: #808080;
  --dark-gray: #3d3d3d;
  --danger-light: #dd4a4a;
  --orange: #b66b00;
  --light-orange: #b66b001c;
  --light-blue: #f2f7ff78;
  --dark-blue: #345068;
  --dark-navy-blue: #164371;
  --dark-gray: #3d3d3d;
  --danger-light: #dd4a4a;
  --orange: #b66b00;
  --light-orange: #b66b001c;
  --5261: #435261;
  --5068: #345068;
  --2e44: #272e44;
  --a32d: #0ca32d;
  --736a: #ff736a;
  --7598: #527598;
  --4453: #454453;
  --3146: #333146;
  --d6e9: #c2d6e9;
  --647d: #47647d;
  --95ab: #8095a8;
  --a74d: #00a74d;
  --f6fc: #f2f6fc;
  --edff: #e8edff;
  --7285: #5c7285;
  --e3ff: #dae3ff;
  --ebff: #e4ebff;
  --1515: #c11515;
  --f2f2: #f2f2f2;
  --a9b1: #9ea9b1;
  --d9d9: #d9d9d9;
  --8192: #6d8192;
  --9959: #009959;
  --4336: #f44336;
  --3765: #283765;
  --FAFA: #fafafa;
  --F8F8: #f8f8f8;
  --2D2D: #2d2d2d;
  --2121: #212121;
  --ecec: #ececec;
  --a500: #ffa500;
  --68d3: #1668d3;
  --4b4b: #4b4b4b;
  --8984: #4589b4;
  --a31a: #00a31a;
  --f3fb: #ecf3fb;
  --2796: #8a2796;
  --224f: #fd8b224f;
  --4c00: #ff4c00;
  --374e: #33374e;
  --e4ff: #d2e4ff;
  --4a50: #464a50;
  --3dcd: #113dcd;
  --f0f2: #eef0f2;
  --e8ff: #d7e8ff;
  --105d: #00105d;
  --0000: #000000;
  --0f30: #080f30;
  --103a: #06103a;
  --64c5: #0c64c5;
  --f3f3: #f3f3f3;
  --c05c: #ffc05c;
  --efd4: #53efd4;
  --e3b7: #ffe3b7;
  --ffed: #95ffed;
  --00ff: #0000ff;
  --1f40: #0b1f40;
  --a21a: #00a21a;
  --67a6: #0f67a6;
  --cd1f: #ffcd1f;
  --f7ff: #f1f7ff;
  --5bff: #e5ebff;
  --5b55: #565b55;
  --4d53: #484d53;
  --e100: #e10000;
  --3700: #b93700;
  --red: #ff0000;
  --48c0: #2148c0;
  --3434: #343434;
  --edff00: #e7edff;
  --9500: #ff9500;
  --4646: #e34646;
  --d1d1: #d1d1d1;
  --f6ff: #fbf6ff;
  --bc60: #23bc60;
  --f6ee: #e4f6ee;
  --a16d: #33a16d;
  --e4e4: #f6e4e4;
  --8c7e: #e68c7e;
  --3746: #283746;
  --737d: #68737d;
  --success: #28a745;
  --danger-bar: #dc3545;
  --warning: #ffc107;
  --1717: #171717;
  --6866: #746866;
  --a35b: #2ba35b;
  --7cff: #4a7cff;
  --f4ff: #ecf4ff;
  --f4fc: #f4f4fc;
  --7cea: #597cea;
  --a700: #e8a700;
  --4141: #454141;
  --5110: #e75110;
  --C991: #c99100;
  --E751: #e75110;
  --E9EE: #e9eeff;
  --FBF6: #fbf6ff;
  --effed: #eeffed;
  --f6de: #fff6de;
  --7490: #65749d;
  --8a8a: #8a8a8a;
  --d180: #d1d1d180;
  --eff3: #eff3ff;
  --f3ff: #eff3ff;
}

$colors: (
  'd180': var(--d180),
  'FBF6': var(--FBF6),
  'E9EE': var(--E9EE),
  'E751': var(--E751),
  'C991': var(--C991),
  'quick-silver': var(--quick-silver),
  'dark-silver': var(--dark-silver),
  'granite-gray': var(--granite-gray),
  'raisin-black': var(--raisin-black),
  'eerie-black': var(--eerie-black),
  'platinum': var(--platinum),
  'ghost-white': var(--ghost-white),
  'new-car': var(--new-car),
  'white': var(--white),
  'american-silver': var(--american-silver),
  'gray': var(--gray),
  'black-olive': var(--black-olive),
  'gainsboro': var(--gainsboro),
  'alice-blue': var(--alice-blue),
  'cetacean-blue': var(--cetacean-blue),
  'dark-charcole': var(--dark-charcole),
  'davys-gray': var(--davys-gray),
  'light-primary': var(--light-primary),
  'new-car-light': var(--new-car-light),
  'title-navy': var(--title-navy),
  'subtitle-navy': var(--subtitle-navy),
  'navy-light': var(--navy-light),
  'danger': var(--danger),
  'moderate-blue': var(--moderate-blue),
  'lime-green': var(--lime-green),
  'skyblue': var(--skyblue),
  'purple': var(--purple),
  'text-navy': var(--text-navy),
  'text-light': var(--text-light),
  'text-blue': var(--text-blue),
  'gray-blue': var(--gray-blue),
  'text-black': var(--text-black),
  'light-sky': var(--light-sky),
  'light-gray': var(--light-gray),
  'dark-gray': var(--dark-gray),
  'danger-light': var(--danger-light),
  'orange': var(--orange),
  'light-orange': var(--light-orange),
  'light-blue': var(--light-blue),
  'dark-blue': var(--dark-blue),
  'dark-navy-blue': var(--dark-navy-blue),
  '5261': var(--5261),
  '5068': var(--5068),
  '2e44': var(--2e44),
  'a32d': var(--a32d),
  '736a': var(--736a),
  '7598': var(--7598),
  '4453': var(--4453),
  '3146': var(--3146),
  'd6e9': var(--d6e9),
  '647d': var(--647d),
  '95ab': var(--95ab),
  'a74d': var(--a74d),
  'f6fc': var(--f6fc),
  'edff': var(--edff),
  '7285': var(--7285),
  'e3ff': var(--e3ff),
  'ebff': var(--ebff),
  '1515': var(--1515),
  'f2f2': var(--f2f2),
  'a9b1': var(--a9b1),
  'd9d9': var(--d9d9),
  '8192': var(--8192),
  '9959': var(--9959),
  '4336': var(--4336),
  '3765': var(--3765),
  'FAFA': var(--FAFA),
  'F8F8': var(--F8F8),
  '2D2D': var(--2D2D),
  '2121': var(--2121),
  'ecec': var(--ecec),
  'a500': var(--a500),
  '68d3': var(--68d3),
  '4b4b': var(--4b4b),
  '8984': var(--8984),
  'a31a': var(--a31a),
  'f3fb': var(--f3fb),
  '2796': var(--2796),
  '224f': var(--224f),
  '4c00': var(--4c00),
  '374e': var(--374e),
  'e4ff': var(--e4ff),
  '4a50': var(--4a50),
  '3dcd': var(--3dcd),
  'f0f2': var(--f0f2),
  'e8ff': var(--e8ff),
  '105d': var(--105d),
  'cd1f': var(--cd1f),
  '0000': var(--0000),
  '0f30': var(--0f30),
  '103a': var(--103a),
  '64c5': var(--64c5),
  'f3f3': var(--f3f3),
  'c05c': var(--c05c),
  'efd4': var(--efd4),
  'e3b7': var(--e3b7),
  'ffed': var(--ffed),
  '00ff': var(--00ff),
  '1f40': var(--1f40),
  'a21a': var(--a21a),
  '67a6': var(--67a6),
  'f7ff': var(--f7ff),
  '5bff': var(--5bff),
  '5b55': var(--5b55),
  '4d53': var(--4d53),
  'e100': var(--e100),
  '3700': var(--3700),
  'red': var(--red),
  '48c0': var(--48c0),
  '3434': var(--3434),
  'black': var(--black),
  'edff00': var(--edff00),
  'd1d1': var(--d1d1),
  '4646': var(--4646),
  '9500': var(--9500),
  'f6ff': var(--f6ff),
  'bc60': var(--bc60),
  'f6ee': var(--f6ee),
  'a16d': var(--a16d),
  'e4e4': var(--e4e4),
  '8c7e': var(--8c7e),
  '3746': var(--3746),
  '737d': var(--737d),
  'success': var(--success),
  'danger-bar': var(--danger-bar),
  'warning': var(--warning),
  '1717': var(--1717),
  '6866': var(--6866),
  'a35b': var(--a35b),
  '7cff': var(--7cff),
  'f4ff': var(--f4ff),
  'f4fc': var(--f4fc),
  '7cea': var(--7cea),
  'a700': var(--a700),
  '4141': var(--4141),
  '5110': var(--5110),
  'effed': var(--effed),
  'f6de': var(--f6de),
  '7490': var(--7490),
  '8a8a': var(--8a8a),
  'eff3': var(--eff3),
  'f3ff': var(--f3ff),
);

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }

  .bg-#{$name} {
    background-color: $color !important;
  }

  .b-#{$name} {
    border: 1px solid $color !important;
  }

  .bb-#{$name} {
    border-bottom: 1px solid $color !important;
  }

  .bt-#{$name} {
    border-top: 1px solid $color !important;
  }

  .br-#{$name} {
    border-right: 1px solid $color !important;
  }

  .bl-#{$name} {
    border-left: 1px solid $color !important;
  }
}
