.payment-verification-details-container {

    ol,
    ul {
        padding-left: 2.5rem;
    }

    .card-height {
        height: 100%;
    }

    .success-payment {
        border: 1.1px solid #2BA35B;
        border-radius: 5px;
        color: #2BA35B;
    }

    .disabled-form {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #e6e6e6
    }

    .image-container {
        height: 520px;
        display: flex;
        align-items: center;
        justify-content: center;

        .invoice-preview {
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}