.recommended-presentations-container {
    .poster {
        // position: relative;
        // height: 200px;

        // .poster-image {
        //     width: 100%;
        //     height: 100%;
        //     object-fit: fill;
        //     object-position: center;
        // }

        // .play-button {
        //     position: absolute;
        //     display: flex;
        //     top: 38%;
        //     right: 45%;
        // }
    }

    .card-border-bottom {
        border: 1px solid #D9E6F7
    }

    .post-title:hover {
        text-decoration: underline;
        text-decoration-color: #1668D3;
        color: #1668D3;
    }
}