.reviewer-paper-status-filter-container {
    position: relative;

    // .option-list {
    //     width: 300px;
    //     max-height: 208px;
    //     border-radius: 8px;
    //     position: absolute;
    //     background: #FFFFFF;
    //     border: 1px solid #F4F4FC;
    //     box-shadow: 0px 4px 6px -2px #10182808 0px 12px 16px -4px #10182814;
    // }


    .css-1u9des2-indicatorSeparator {
        display: none;
    }



    .css-13cymwt-control,
    .css-t3ipsp-control {
        width: 100%;
        min-height: 45px;
        padding: 0px 8px 0px 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        border-radius: 4px;
        box-shadow: none !important;

    }



    .select__menu {
        position: absolute;
        width: 300px;
        right: 0 !important;
        border-radius: 8px;
        position: absolute;
        background: #FFFFFF;
        border: 1px solid #F4F4FC;
        box-shadow: 0px 4px 6px -2px #10182808 0px 12px 16px -4px #10182814;

        .css-1n6sfyn-MenuList {
            max-height: 135px;
            overflow-y: auto;
            scroll-behavior: smooth;
            position: relative;
            -webkit-overflow-scrolling: touch;
            padding-bottom: 4px;
            padding-top: 4px;
            box-sizing: border-box;
        }

        .css-1n6sfyn-MenuList {
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px transparent;
                background-color: var(--ghost-white);
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: var(--ghost-white);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                background-color: var(--gray);
            }
        }
    }

    .css-xb97g8:hover {
        background-color: var(--alice-blue);
        color: var(--new-car);
    }

    // .css-1n6sfyn-MenuList {
    //     &::-webkit-scrollbar-track {
    //         -webkit-box-shadow: inset 0 0 6px transparent;
    //         background-color: var(--ghost-white);
    //         border-radius: 10px;
    //     }

    //     &::-webkit-scrollbar {
    //         width: 5px;
    //         height: 5px;
    //         background-color: var(--ghost-white);
    //     }

    //     &::-webkit-scrollbar-thumb {
    //         border-radius: 0px;
    //         background-color: var(--gray);
    //     }
    // }

    .css-10wo9uf-option,
    .css-d7l1ni-option,
    .css-tr4s17-option {
        width: 100%;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 10px 14px !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        color: var(---black-olive) !important;
    }

    .css-tr4s17-option {
        background-color: inherit !important;
    }

    .select__clear-indicator {
        display: none !important;
    }


    .custom-list:nth-child(odd) {
        background-color: #F4F4FC;
    }

    .custom-list:nth-child(even) {
        background-color: #FFFFFF;

    }

    .css-1p3m7a8-multiValue {
        background-color: #E9EEFF !important;
        color: #2148C0 !important;
        border-radius: 2px !important;

        .css-wsp0cs-MultiValueGeneric {

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #2148C0 !important;
            font-size: 85%;
            padding: 4px 8px;

        }
    }
}