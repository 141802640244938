
#approvals-continer{

  .mentor-pending-count {
    position: absolute;
    top: 0;
    right: -15px;
    width: 20px;
    height: 20px;
    background-color: #2148c0;
    border-radius: 100%;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .session-pending-count {
    position: absolute;
    top: 0;
    right: -15px;
    width: 20px;
    height: 20px;
    background-color: #2148c0;
    border-radius: 100%;
    color: white;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#button-container {
  .mentor-approve-btn {
    color: #fff;
    border-radius: 4px;
    border: 1px solid #2148c0;
    background: #2148c0;
  }
  .reject-mentor-approve-btn {
    color: #d0232d;
    border-radius: 4px;
    border: 1px solid #d0232d;
    background: #fff;
  }
  .approved-mentor-approve-btn {
    color: #164371;
    border-radius: 4px;
    border: 1px solid #164371;
    background: #fff;
  }
}
