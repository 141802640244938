#radio-input-container {
  display: flex;
  align-items: center;
  .radio-block {
    height: 15px;
    width: 15px;
    border: 2px solid #5b5b5b;
    :disabled {
      background-color: #e6e6e6;
      color: #212121;
    }
  }
  .radio-block:checked {
    accent-color: var(--new-car);
  }
}
