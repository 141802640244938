#dropdown-button-container {
  width: 100%;
  .dropdown-toggle::after {
    display: none;
  }
  #dropdown-btn {
    border-radius: 2px;
    background-color: var(--white);
    color: var(--black-olive);
    border: 1px solid var(--gray);
    outline: none;
    display: flex;
    align-items: center;
    box-shadow: unset;
    :focus {
      box-shadow: unset;
      border: unset;
    }
  }
  .dropdown-body-container {
    width: 286px;
    // margin-top: 14px;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 4px 16px #00000024;
    border: unset;
  }
  .h-35 {
    height: 35px;
  }
  .h-20 {
    height: 20px;
  }
  .border-b {
    border-bottom: 1px solid #ebeff2;
  }
  .border-b:last-child {
    border-bottom: unset;
  }
  #iconType {
    width: 100%;
    border-radius: 2px;
    background-color: transparent;
    color: var(--black-olive);
    border: 1px solid var(--gray);
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: unset;
    :focus {
      box-shadow: unset;
      border: unset;
    }
    padding: unset;
    border: unset !important;
    .dropdown-arrow {
      display: none;
    }
  }
  .dropdown-body-container {
    width: 335px;
  }
}
.post-options {
  width: unset !important;
  .dropdown-body-container {
    width: 200px !important;
    padding-left: unset !important;
    padding-right: unset !important;
  }
}
#email-drop-down {
  border: unset;
  background-color: unset;
  padding-left: unset;
  padding-top: unset;
  padding-bottom: unset;
  &:focus {
    outline: none;
    background-color: unset;
    box-shadow: unset;
  }
}
#op-drop-down {
  border: unset;
  background-color: unset;
  padding-left: unset;
  padding-right: unset;
  &:focus {
    outline: none;
    background-color: unset;
    box-shadow: unset;
  }
  .bi-chevron-down::before {
    display: none;
  }
}
.op-drop-down {
  .dropdown-body-container {
    width: 100px !important;
  }
}
.show.dropdown {
  #dropdown-btn {
    color: var(--new-car) !important;
    border: 1px solid var(--new-car) !important;
    img {
      filter: invert(23%) sepia(42%) saturate(4637%) hue-rotate(221deg)
        brightness(82%) contrast(95%);
    }
  }
  #email-drop-down {
    color: unset;
    border: unset;
    img {
      filter: unset;
    }
  }
  #op-drop-down {
    color: unset;
    border: unset;
    img {
      filter: unset;
    }
  }
}
