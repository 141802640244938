#new-global-navbar-container {
    border-bottom: 1px solid rgba(224, 233, 244, 0.6);
    box-shadow: 0px 1px 16px 0px #0000001C;
    padding: 10px 50px;


    .desktop-logo-container {
        padding: 8px;
    }

    .main-con {
        // width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // align-items: center;

        // .left-logo {
        //     // width: 25%;
        // }

        // .center-search {
        //     width: 35%;
        //     display: flex;

        // }

        // .right-button {
        //     display: flex;
        //     justify-content: flex-end;
        //     flex-grow: 1;
        // }
    }
}