#Sidebar-container {
  .desktop-logo-container {
    padding: 8px;
  }

  // .sidemenu-block{
  // img {
  //  filter: invert(13%) sepia(16%) saturate(4594%) hue-rotate(193deg) brightness(86%) contrast(106%);
  // }
  // }

  .active-option {
    background: #EFF3FF;
    border-radius: 5px;
    color: #2148C0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;
    margin: 5px 0px;
    border-left: 4px solid #2148C0;

    img {
      filter: brightness(0) saturate(100%) invert(21%) sepia(52%) saturate(3876%) hue-rotate(218deg) brightness(93%) contrast(86%);
      // filter: brightness(120%) contrast(250%) grayscale(100%) invert(100%);
    }
  }
}