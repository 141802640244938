#TextInput-container {
  width: 100%;
  .label-badge{
    position: absolute;
    line-height: 17.5px;
    background: #FFF2DE;
    color: #E75110;
    top: -5px;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 12px;
    border-radius: 5px;
    left: 150px;
  }

  .input-container {
    position: relative;

    .icon-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      margin-right: 8px;
      top: 15px;
      left: 12px;
      color: #746866;
    }

    .cancel-block {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: var(--new-car);
      color: var(--white);
      padding: 2px 6px 2px 6px;
      border-radius: 2px;
      font-size: 14px;
      cursor: pointer;
    }

    .dialcode-selection select {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 0px 13px 13px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
    }

    .text-input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      white-space: nowrap;
      // padding-right: 105px;
      text-overflow: ellipsis;
    }

    .button-padding {
      padding: 13px 65px 13px 16px;
    }

    ::placeholder {
      color: var(--granite-gray);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      color: var(--granite-gray);
    }

    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    :disabled {
      background-color: #e6e6e6;
      color: #212121;
    }

    .phone-redius {
      border-radius: 0px 4px 4px 0px;
      padding: 13px 16px 13px 10px;
    }
  }
}