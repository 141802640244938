#export-button-container {
  display: flex;

}

.date-input-container {
  width: 100%;

  .input-container {
    position: relative;

    .text-input {
      width: 100%;
      border-radius: 4px;
      outline: none;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
      white-space: nowrap;
      // padding-right: 105px;
      text-overflow: ellipsis;
    }

    .close-icon {
      position: absolute;
      right: 8px;
      top: 13px;
      background-color: transparent;
      padding: 0px 5px 0px 5px;
      display: flex;

      img {
        height: 18px;
      }
    }

    .calender-icon {
      position: absolute;
      right: 8px;
      top: 13px;
      background-color: transparent;
      padding: 0px 5px 0px 5px;
      display: flex;

      img {
        height: 18px;
      }
    }
  }

}

.type-block-container {
  border: 1px solid #dedede;
  border-radius: 2px;
  padding: 15px 25px 10px 25px;
  height: 110px;
}