#SeachInput-container {
  width: 100%;

  .input-container {
    position: relative;

    .search-icon {
      display: flex;
      position: absolute;
      right: 13px;
      top: 12px;

      img {
        height: 21px;
        width: 21px;
      }
    }

    .loader-icon {
      position: absolute;
      right: 15px;
      top: 14px;

      .spinner-border-sm {
        --bs-spinner-border-width: 0.1em;
      }
    }

    .text-input {
      width: 100%;
      border-radius: 6px;
      outline: none;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 35px 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
    }

    .round-text-input {
      width: 100%;
      border-radius: 21px;
      outline: none;
      border: 1px solid var(--american-silver);
      height: 45px;
      padding: 13px 35px 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.5px;
    }

    ::placeholder {
      color: var(--granite-gray);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      color: var(--granite-gray);
    }

    ::-ms-input-placeholder {
      color: var(--granite-gray);
    }
  }
}