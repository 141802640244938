.upcoming-activities-container {
    .date-block {
        border-radius: 4px;
        background-color: #8A2796;
    }

    .date-border-bottom {
        border-bottom: 1px solid #B75AC2;
    }

    .view-more-decoration {
        text-decoration: underline;
        text-decoration-color: var(--4c00);
    }

    .card-border-bottom {
        border: 1px solid #D9E6F7
    }
}